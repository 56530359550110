<template>
  <tableau view="calls/sheet4"/>
</template>

<script>
import Tableau from "./Tableau"

export default {
  components: { Tableau }
}
</script>
